import React, { useEffect, useState } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web'

const isSSR = typeof window === 'undefined'

function withKeycloak(WrappedElement) {
  var initialized = false
  return (props) => {
    const [keycloak, setKeycloak] = useState({
      authenticated: false,
      init: ()=>{

      }
    })

    useEffect(() => {
      if (isSSR) return;
      if (initialized) return;

      import('keycloak-js').then(Keycloak => {
        const kc = new Keycloak.default({
          realm: process.env.KEYCLOAK_REALM,
          url: process.env.KEYCLOAK_URL,
          clientId: process.env.KEYCLOAK_CLIENT_ID
        })

        initialized = true
        setKeycloak(kc)
      })
    })

    return (
      <ReactKeycloakProvider
        keycloak={keycloak}
        initConfig={{
          promiseType: 'native',
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri:
            isSSR ? null : window.location.origin + '/silent-check-sso.xhtml'
        }}
        // LoadingComponent={<Loading />}
        onError={(event, error) => {
          console.log('onKeycloakError', event, error)
        }}
        onEvent={(event, error) => {
          console.log('onKeycloakEvent', event, error)
        }}
      >
        <WrappedElement {...props}></WrappedElement>
      </ReactKeycloakProvider>
    )
  }
}

export default withKeycloak