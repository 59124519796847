import React from 'react'
import withKeycloak from '../lib/keycloak'

import Login from '../components/Login'

function TestKeycloak() {
    return (
        withKeycloak(<Login></Login>)
    )
}

export default TestKeycloak